body {
  margin: 0;
  font-family: "Pixelify Sans", sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  background-color: rgb(0, 0, 0);
  color: white;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, "Courier New", monospace;
}

.main {
  backdrop-filter: blur(10px);
  -webkit-backdrop-filter: blur(10px);
}

.hoverWhite {
  color: grey;
}
.hoverWhite:hover {
  color: lightgray;
}

.dot {
  margin-right: 5px;
  width: 8px;
  height: 8px;
  background-color: yellow;
  border-radius: 50%;
  animation: flash 1s infinite alternate; /* Flashing animation */
}

.flash {
  color: yellow;
  animation: flash 1s infinite alternate; /* Flashing animation */
}

@keyframes flash {
  0% {
    opacity: 1; /* Fully visible */
  }
  100% {
    opacity: 0; /* Fully transparent */
  }
}

.meteor-shower {
  position: fixed;
  width: 100%;
  height: 100%;
  overflow: hidden;
}

.meteor {
  position: absolute;
  opacity: 0;
  transform: rotate(45deg);
}

.meteor.triangle {
  width: 0;
  height: 0;
  border-left: 10px solid transparent;
  border-right: 10px solid transparent;
  border-bottom: 20px solid rgb(248, 255, 209);
  animation: fall 10s linear infinite; /* Add duration here */
}

.meteor.square {
  width: 15px;
  height: 15px;
  background: rgb(230, 202, 255);
  animation: fall3 10s linear infinite; /* Add duration here */
}

.meteor.circle {
  width: 15px;
  height: 15px;
  background: rgb(204, 255, 213);
  border-radius: 50%;
  animation: fall2 10s linear infinite; /* Add duration here */
}

@keyframes fall {
  from {
    transform: translateY(0vh) translateX(0vw);
    opacity: 1;
    filter: drop-shadow(0 0 10px rgba(255, 255, 255, 1));
  }
  to {
    transform: translateY(100vh) translateX(-100vw) rotate(180deg);
    opacity: 0;
    filter: drop-shadow(0 0 10px rgba(255, 255, 255, 0.7));
  }
}
@keyframes fall2 {
  from {
    transform: translateY(0vh) translateX(0vw);
    opacity: 1;
    filter: drop-shadow(0 0 10px rgba(255, 255, 255, 1));
  }
  to {
    transform: translateY(100vh) translateX(100vw) rotate(180deg);
    opacity: 0;
  }
}

@keyframes fall3 {
  from {
    transform: translateY(0vh);
    opacity: 1;
    filter: drop-shadow(0 0 10px rgba(255, 255, 255, 1));
  }
  to {
    transform: translateY(100vh) rotate(180deg);
    opacity: 0;
  }
}

/* Glass effect */
.glass {
  position: relative;
  padding: 10px;
  border-radius: 10px;
  box-shadow: 0 4px 6px rgba(0, 0, 0, 0.1);
  backdrop-filter: blur(10px);
  -webkit-backdrop-filter: blur(10px);
  color: #fff;
  text-align: center;
}

.wallet-adapter-button-trigger {
  background-color: #263238 !important;
}
.wallet-adapter-button-trigger:hover {
  background-color: #455a64 !important;
}

/* Wrapper to prevent overflow */
.glitch-wrapper {
  position: relative;
  overflow: hidden;
}

/* Glitched text styling */
.glitched-text {
  position: relative;
  color: #fff;
  font-size: 160px;
  font-weight: bold;
  text-transform: uppercase;
  letter-spacing: 2px;
  display: inline-block;
}

.glitched-text::before,
.glitched-text::after {
  content: attr(data-text);
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  color: #fff;
  background: transparent;
  overflow: hidden;
  clip: rect(0, 900px, 0, 0);
}

.glitched-text::before {
  left: 2px;
  text-shadow: -2px 0 red;
  animation: glitch-anim 4s infinite linear alternate-reverse;
}

.glitched-text::after {
  left: -2px;
  text-shadow: -2px 0 blue;
  animation: glitch-anim2 2s infinite linear alternate-reverse;
}

@keyframes glitch-anim {
  0%,
  100% {
    clip: rect(0, 9999px, 160px, 0);
    transform: skew(0deg);
    color: #fff;
    text-shadow: -2px 0 red, 2px 0 yellow; /* Random colors */
  }
  5% {
    clip: rect(26px, 9999px, 96px, 0);
    transform: skew(-0.2deg);
  }
  10% {
    clip: rect(72px, 9999px, 78px, 0);
    transform: skew(0.4deg);
  }
  /* Add more glitch animation keyframes here */
}

@keyframes glitch-anim2 {
  0%,
  100% {
    clip: rect(0, 9999px, 160px, 0);
    transform: skew(0deg);
    color: #fff;
    text-shadow: -2px 0 blue, 2px 0 green; /* Random colors */
  }
  5% {
    clip: rect(42px, 9999px, 108px, 0);
    transform: skew(0.3deg);
  }
  10% {
    clip: rect(86px, 9999px, 96px, 0);
    transform: skew(-0.2deg);
  }
  /* Add more glitch animation keyframes here */
}

/* ShakingList.css */
.shake-animation {
  animation: shakeAnimation 1s ease;
}

@keyframes shakeAnimation {
  0% {
    transform: translateX(-20px);
  }
  20% {
    transform: translateX(20px);
  }
  40% {
    transform: translateX(-20px);
  }
  60% {
    transform: translateX(20px);
  }
  80% {
    transform: translateX(-20px);
  }
  100% {
    transform: translateX(0px);
  }
}
